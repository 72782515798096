import React from 'react'
import Helmet from 'react-helmet'

import {
  Trans,
  useTranslation,
  I18nContext,
  useI18next,
} from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout'
// import Lightbox from 'react-images'
import Gallery from '../components/Gallery'

import thumb01 from '../assets/images/thumbs/01.jpg'
import thumb02 from '../assets/images/thumbs/02.jpg'
import thumb03 from '../assets/images/thumbs/03.jpg'
import thumb05 from '../assets/images/thumbs/05.jpg'

import full01 from '../assets/images/fulls/01.jpg'
import full02 from '../assets/images/fulls/02.jpg'
import full03 from '../assets/images/fulls/03.jpg'
import full05 from '../assets/images/fulls/05.jpg'

const DEFAULT_IMAGES = [
  { id: '1', source: full01, thumbnail: thumb01 },
  { id: '2', source: full02, thumbnail: thumb02 },
  { id: '3', source: full03, thumbnail: thumb03 },
  { id: '5', source: full05, thumbnail: thumb05 },
]

const HomeIndex = () => {
  const { t } = useTranslation()
  const context = React.useContext(I18nContext).i18n
  const { languages, originalPath } = useI18next()
  console.log('Language: ', context.language)
  const siteTitle = t('Jake and Hara are getting married')
  const siteDescription = t("Jake and Hara's Wedding Website")
  const googleMapsIframeHtml = {
    __html:
      '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12565.555001361212!2d23.9979438!3d38.0613267!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5caa2efe4ca48059!2sSea%20Soul!5e0!3m2!1sen!2suk!4v1569174125419!5m2!1sen!2suk" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>',
  }

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
      </Helmet>

      <div id="main">
        <section id="one">
          <header className="major">
            <h2>
              <Trans>
                Jake and Hara got married in Athens, Greece on Friday,
                29th July 2022!
              </Trans>
            </h2>
          </header>
        </section>

        <section id="info">
          <h2>Thank you all for coming!</h2>
          <h3>Pictures</h3>
          <p>
            If you share any photos of the wedding, it would be great if you could use the hashtag
            #JakeAndHara2022 so that we're able to find them!

            If you'd like to share them directly with us (and see everyone else's pictures too!)
            you can use our <a></a> <a id="googlePhotosLink" href="https://photos.app.goo.gl/gzr8FArjvnZL1d5V9">Google Photos album here</a>.
          </p>
        </section>

        <section>
          <h2>
            <Trans>Wedding list</Trans>
          </h2>
          <p>
            Your presence at the wedding was a gift enough, but if you'd
            like to contribute towards our honeymoon, we've registered with
            Trailfinders.
            <br />
            <br />
            <Trans>You can find our wishlist</Trans>{' '}
            <a href="https://www.trailfinders.com/wishlist#/faf-login">
              <Trans>here</Trans>
            </a>{' '}
            <Trans>
              and log in using surname 'Mckenna' and reference '5BKFEM'{' '}
            </Trans>
          </p>
        </section>


        <section id="four">
          <h2>
            <Trans>Get In Touch</Trans>
          </h2>
          <p>
            <Trans>
              You probably know how to reach us already, but you can
            </Trans>{' '}
            <a href={'mailto:contact@jakeandhara.com'}>
              <Trans>email us here</Trans>
            </a>
            <Trans>if you'd like!</Trans>
          </p>
        </section>
      </div>
    </Layout>
  )
}

export default HomeIndex
